import React, { Component } from 'react';
import "./styles/styles.css";
import SideBar from "./navigation/sidebar";
import {NavBarButtons} from "./navigation/buttons/nav_bar_buttons";

class Header extends Component {

render() {
    return (
			<div id="outer-container">
      			<div className="navbar navbar-dark bg-dark">
      				<SideBar />
        			<NavBarButtons />
				</div>
			</div>
 	);
}
}

export default Header;
	
	
	