//import { Link, NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { slide as Menu } from "react-burger-menu";
import React, { useState } from "react";


const SideBar = () => {

  	const [isMenuOpen, handleMenu] = useState(false);
  	const handleCloseMenu = () => {
    	handleMenu(false);
  	};
  	const handleStateChange = (state) => {
    	handleMenu(state.isOpen);
  	};
  
  return (
   
  
    	<Menu isOpen={isMenuOpen} onStateChange={handleStateChange} pageWrapId={"page-wrap"} outerContainerId={"outer-container"} disableAutoFocus width="400px" noOverlay={true} disableCloseOnEsc={true}>
    	
			<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/activitycalendar">
    			Activity Calendar
    			<img src="https://newstats.firehole.us/icons/activity_calendar.png" alt="activity calendar icon" className="menu_icons"></img>
    		</Link> 
			<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/whoopactivity">
        		Whoop Activities
        		<img src="https://newstats.firehole.us/icons/whoop_icon2.png" alt="Whoop icon" className="menu_icons"></img>
      		</Link>
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/whoopsleep">
        		Whoop Sleep
        		<img src="https://newstats.firehole.us/icons/whoop_sleep.png" alt="Whoop icon" className="menu_icons"></img>
      		</Link>
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/whooprecovery">
        		Whoop Recovery
        		<img src="https://newstats.firehole.us/icons/whoop_recovery.png" alt="Whoop icon" className="menu_icons"></img>
      		</Link>
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/googlefit">
        		Google Fit
        		<img src="https://newstats.firehole.us/icons/google_fit.png" alt="GoogleFit icon" className="menu_icons"></img>
      		</Link>
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/stravaactivity">
        		Strava
        		<img src="https://newstats.firehole.us/icons/strava.png" alt="Strava icon" className="menu_icons"></img>
      		</Link>
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/withings">
    			Withings
    			<img src="https://newstats.firehole.us/icons/withings.png" alt="withings icon" className="menu_icons"></img>
    		</Link>   
    		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/peloton">
    			Peloton
    			<img src="https://newstats.firehole.us/icons/peloton.png" alt="peloton icon" className="menu_icons"></img>
    		</Link>   
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/smashrunrunslist">
        		Runs - List
        		<img src="https://newstats.firehole.us/icons/calendar_30.png" alt="runs list icon" className="menu_icons"></img>
      		</Link>
      		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/weeklymonthly">
        		Runs - Weekly, Monthly, This Day in History
        		<img src="https://newstats.firehole.us/icons/calendar.png" alt="weekly monthly icon" className="menu_icons"></img>
      		</Link>
    		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/historical">
    			Runs - Historical Statistics
    			<img src="https://newstats.firehole.us/icons/historical.png" alt="historical icon" className="menu_icons"></img>
    		</Link>
    		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/bestraces">
    			Runs - Best Races
    			<img src="https://newstats.firehole.us/icons/racers.png" alt="bestraces icon" className="menu_icons"></img>
    		</Link>
    		<Link onClick={()=>handleCloseMenu()} className="menu-item" to="/favoriteroutes">
    			Runs - Route Information
    			<img src="https://newstats.firehole.us/icons/routes.png" alt="favorite routes icon" className="menu_icons"></img>
    		</Link>     
    		
    	</Menu>

  );
};
export default SideBar;
