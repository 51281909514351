import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from "./page-loader";

import Smashrunrunslist from './services/smashrunrunslist';
import Whoopactivity from './services/whoopactivity';
import Whoopsleep from './services/whoopsleep';
import Whooprecovery from './services/whooprecovery'
import Googlefit from './services/googlefit'
import Weeklymonthly from './services/weeklymonthly';
import Historical from './services/historical';
import Bestraces from './services/bestraces';
import Favoriteroutes from './services/favoriteroutes';
import Stravaactivity from './services/stravaactivity';
import Activitycalendar from './services/Activitycalendar';
import Withings from './services/Withings';
import Peloton from './services/Peloton';
import Pelotonspecificone from './services/Pelotonspecificone';
import Smashrunspecificone from './services/Smashrunspecificone';

import Header from './header';
import "./styles/styles.css";

function App() {	
  		
  		const { isAuthenticated, isLoading } = useAuth0();
  		
  		//get JWT token for calling the APIs
  		
  		const { getAccessTokenSilently } = useAuth0();
  		useEffect(() => {
  			(async() => {
  				const accessToken = await getAccessTokenSilently({
       				authorizationParams: {
          			audience: `https://services.firehole.us/api/whoop_sleep_api/`,
        			},
  				});	
  			
  				localStorage.setItem('token', JSON.stringify(accessToken));
  				
  			})();
  		});	
  		
  		if (isLoading) {
      			return (
      				<div className="page-layout">
        				<PageLoader />
      				</div>
    			);
  		}
  		fetch('https://services.firehole.us/api/whoop')
  		fetch('https://services.firehole.us/api/smashruntolocal/')		  		
  		fetch('https://services.firehole.us/api/peloton/')


  		//console.log("Access Token from App.js " + JSON.parse(localStorage['token']));
		return(
  			
  			<Router>
	  			
	  			<Header />
	  			{isAuthenticated && (
        		<>

        		<div className="navbar navbar-dark bg-dark">
        			<div className="navbar-brand mx-auto text-center fs-3">Firehole Health</div>
        		</div>
        		
	 			<Routes> 	
      					
      				<Route exact path="/" element={<Activitycalendar />} />	
      				<Route path="/smashrunrunslist" element={<Smashrunrunslist />} />
      				<Route path = "/whoopactivity" element={<Whoopactivity />} />
      				<Route path = "/whoopsleep" element={<Whoopsleep />} />
      				<Route path = "/whooprecovery" element={<Whooprecovery />} />
      				<Route path = "/googlefit" element={<Googlefit />} />
      				<Route path="/weeklymonthly" element={<Weeklymonthly />} />
      				<Route path="/historical" element={<Historical />} />
      				<Route path="/bestraces" element={<Bestraces />} />
      				<Route path="/favoriteroutes" element={<Favoriteroutes />} />
      				<Route path="/stravaactivity" element={<Stravaactivity />} />
      				<Route path="/activitycalendar" element={<Activitycalendar />} />
    				<Route path="/withings" element={<Withings />} />
    				<Route path="/peloton" element={<Peloton />} />		
    				<Route path="/pelotonspecificone" element={<Pelotonspecificone />} /> 	 
    				<Route path="/smashrunspecificone" element={<Smashrunspecificone />} />   	
    					
      			</Routes>
      			</>
      			)}
      			{!isAuthenticated && (
        		<>
        			<div className="navbar navbar-dark bg-dark">
        				<div className="navbar-brand mx-auto text-center fs-3">Firehole Health</div>
        			</div>
        			<div className="text-center mx-auto">Please Login.</div>
        		</>
        		)}
        		
  			</Router>
		)
//	}
}

export default App;
